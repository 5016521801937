import './App.css';

function App() {
  return (
    <div className="App">
      <h1>Hello, world from Soni v2!!</h1>
      <h1>Hello, world from dilli</h1>
    </div>
  );
}

export default App;
